import Vue from 'vue'
import store from '@/store/index'
import VueRouter from 'vue-router'
import LoginView from '../views/LoginView.vue'
// import navbar from '@/views/navbar'
import homeView from '@/views/home/homeView'
import equipment from '@/views/equipment/equipment'
import history from '@/views/equipment/history/history'
import maintenance from '@/views/equipment/maintenance/maintenance'
import information from '@/views/equipment/information/information'
import electric from '@/views/equipment/electric/electric'
import * as storage from '@/utils/request'
import effectiveValue from '@/views/equipment/electric/effectiveValue'
import power from '@/views/equipment/electric/power'
import powerFactor from '@/views/equipment/electric/powerFactor'
import harmonicWave from '@/views/equipment/electric/harmonicWave'
import liveData from '@/views/equipment/liveData/liveData'
import warn from '@/views/equipment/warn/warn'
import harmonicWaveValue from '@/views/equipment/electric/harmonicWaveValue'
import inverter from '@/views/equipment/inverter/inverter'
import photovoltaicMonitor from '@/views/equipment/inverter/photovoltaicMonitor/photovoltaicMonitor'
import photovoltaicOperation from '@/views/equipment/inverter/operation/photovoltaicOperation'
import photovoltaicStatistics from '@/views/equipment/inverter/statistics/photovoltaicStatistics'
import serviceInfo from '@/views/equipment/serviceInfo/serviceInfo'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    component: LoginView
  },
  {
    path: '/nav',
    name: 'navbar',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/navbar.vue'),
    redirect: '/home',
    children: [
      {
        path: '/home',
        name: 'home',
        component: homeView
      },
      {
        path: '/equipment',
        name: 'equipment',
        component: equipment,
        redirect: '/information',
        children: [
          {
            path: '/information',
            name: 'information',
            component: information
          },
          {
            path: '/electric',
            name: electric,
            component: electric,
            redirect: '/power',
            children: [{
              path: '/effectiveValue',
              name: 'effectiveValue',
              component: effectiveValue
            },
            {
              path: '/power',
              name: 'power',
              component: power
            },
            {
              path: '/powerFactor',
              name: 'powerFactor',
              component: powerFactor
            },
            {
              path: '/harmonicWave',
              name: 'harmonicWave',
              component: harmonicWave
            },
            {
              path: '/harmonicWaveValue',
              name: 'harmonicWaveValue',
              component: harmonicWaveValue
            }
            ]
          },
          {
            path: '/liveData',
            name: 'liveData',
            component: liveData
          },
          {
            path: '/history',
            name: 'history',
            component: history
          },
          {
            path: '/warn',
            name: 'warn',
            component: warn
          },
          {
            path: '/maintenance',
            name: 'maintenance',
            component: maintenance
          },
          {
            path: '/inverter',
            name: 'inverter',
            component: inverter,
            redirect: '/photovoltaicStatistics',
            children: [{
              // 光伏监测
              path: '/photovoltaicMonitor',
              name: 'photovoltaicMonitor',
              component: photovoltaicMonitor
            },
            {
              // 运行情况
              path: '/photovoltaicOperation',
              name: 'photovoltaicOperation',
              component: photovoltaicOperation
            },
            {
              // 日月年数据统计
              path: '/photovoltaicStatistics',
              name: 'photovoltaicStatistics',
              component: photovoltaicStatistics
            }
            ]
          },
          // 续费服务详情
          {
            path: '/serviceInfo',
            name: 'serviceInfo',
            component: serviceInfo
          },
        ]
      }
    ]
  }
]

const router = new VueRouter({
  routes
})
// 设置路由守卫，在进页面之前，判断有token，才进入页面，否则返回登录页面
router.beforeEach((to, from, next) => {
  // 如果访问的是登录页面
  if (to.path === '/login') return next()
  // 如果访问的不是登录页面，则要先检查是否有token
  const tokenStr = window.localStorage.getItem('token')
  // 如果没有token
  if (!tokenStr) return next('/login')
  // 如果有token
  next()
})

export default router
