// import request from '../utils/request'
import { get, post } from '@/utils/request'

// export function postLogin (data) {
//   return request({
//     url: '/loginForMobileController/toLogin',
//     method: 'post',
//     data
//   })
// }
export const postLogin = params => post('/loginForMobileController/toLogin', params)// 登录
export const postUserList = params => post('/sysEnterpriseForMobileController/getSysEnterpriseChild', params)// 获取当前客户子集(信息)
export const postEquipmentList = params => post('/deviceForMobileController/getDeviceListByEnId', params)// 根据客户标识获取用户设备列表
export const postGetDeviceInfo = params => post('/deviceForMobileController/getDeviceInfoByDevId', params)// 根据设备标识获取设备详情
export const postEffectiveValue = params => post('/deviceForMobileController/getDevRmsStatistics', params)// 获取有效值
export const postPowerValue = params => post('/deviceForMobileController/getDevPqStatistics', params)// 获取功率
export const postPowerFactorValue = params => post('/deviceForMobileController/getDevPFStatistics', params)// 获取功率因素(月)
export const postPowerFactorDayValue = params => post('/deviceForMobileController/getDevPFDay', params)// 获取功率因素(日)
export const postDevInPacMonthStatistics = params => post('/deviceForMobileController/getDevInPacMONTHStatistics', params)// 获取发电量列表(月)
export const postDevInPacDayStatistics = params => post('/deviceForMobileController/getDevInPacDayStatistics', params)// 获取发电量列表(日)
export const postHarmonicWave = params => post('/deviceForMobileController/getDevThdStatistics', params)// 获取谐波畸变率
export const postHistory = params => post('/deviceForMobileController/getCtwDeviceDetialListByDate', params)// 获取历史数据
export const postCurrentValue = params => post('/deviceForMobileController/getDevActualTimeData', params)// 获取实时数据
export const postHomeView = params => post('/mainStatisticsForWebController/getMainInfo', params)// 获取首页
export const postHarmonicWaveValueList = params => post('/deviceForMobileController/getDevThdList', params)// 获取谐波率列表
export const postHarmonicWaveValue = params => post('/deviceForMobileController/getDevFFtStatistics', params)// 获取谐波含量
export const postWarnMessage = params => post('/deviceForMobileController/getDeviceAlarmRecordList', params)// 获取告警
export const postLogout = params => post('/loginForMobileController/loginOut', params)// 退出
export const postAlarmMessage = params => post('/deviceForMobileController/getAlarmRecordInfoByArId', params)// 获取首页告警详情
export const postDeviceMaintenanceList = params => post('/deviceForMobileController/getDeviceMaintenanceList', params)// 获取首页告警详情
/*  光伏 */
export const postGetInverterEday = params => post('/deviceForMobileController/getInverterEday', params)// 获取今日发电量
export const postGetInverterEtotal = params => post('/deviceForMobileController/getInverterEtotal', params)// 获取累计发电量
export const postGetInverterTempperature = params => post('/deviceForMobileController/getInverterTempperature', params)// 获取温度
export const postGetInverterOperation = params => post('/deviceForMobileController/getInverterPacByDay', params)// 获取功率曲线
export const postGetInverterACVoltage = params => post('/deviceForMobileController/getInverterVac', params)// 获取交流电压统计
export const postGetInverterSubList = params => post('/deviceForMobileController/getInventersSubList', params)// 获取电站逆变器列表
export const postGetInverterATInfoBySn = params => post('/deviceForMobileController/getInventersATInfoBySn', params)// 获取逆变器实时数据
export const postGetInverterSubInfoBySn = params => post('/deviceForMobileController/getInventersSubInfoBySn', params)// 获取逆变器详情列表
export const postGetServiceInfo = params => post('/deviceForMobileController/getInvoiceList', params)// 获取逆变器详情列表




















