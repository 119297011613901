<template>
  <div class="main" style="width: 100%;height: 100%">
    <div class="top">
      <div class="chooseYear" style="padding-top: 15px;line-height: 0;text-align: left;padding-left: 10px" >
        <el-select v-model="value" placeholder="请选择年份" @change="getYear()">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="center" style="height: 270px;margin-top: 15px;width: 100%">
        <div class="left" style="width:73%;float: left">
          <el-table
            :data="tableData" style="height: 300px"
            max-height="290px" @row-click="clickDate">
            <el-table-column
              prop="time"
              align="center"
              label="时间"
              width="150px">
            </el-table-column>
            <el-table-column
              prop="in_pac"
              align="center"
              label="正向有功电量"
              width="180px">
            </el-table-column>
            <el-table-column
              prop="wp_minus_m"
              align="center"
              label="反向有功电量"
              width="180px">
            </el-table-column>
            <el-table-column
              prop="month_capacity"
              align="center"
              label="当月容量"
              width="180px">
            </el-table-column>
            <el-table-column
              prop="wp_plus1"
              align="center"
              label="峰"
              width="164px">
            </el-table-column>
            <el-table-column
              prop="wp_plus2"
              align="center"
              label="谷"
              width="164px">
            </el-table-column>
            <el-table-column
              prop="wp_plus3"
              align="center"
              label="平"
              width="164px">
            </el-table-column>
          </el-table>
        </div>
        <div class="left" style="width:26%;float: right">
          <el-table
            :data="tableDataDay" style="height: 300px"
            max-height="290px" >
            <el-table-column
              prop="time"
              align="center"
              label="时间"
              width="170px">
            </el-table-column>
            <el-table-column
              prop="in_pac"
              align="center"
              label="电量"
              width="220px">
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <div class="bottom" style="height:405px;margin-top: 60px">
      <div id="echartU" style="height: 405px;width: 1500px;float: left"></div>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import { postDevInPacDayStatistics, postDevInPacMonthStatistics } from '@/api/api'

export default {
  name: 'effectiveValue',
  data () {
    return {
      date: '',
      time: '',
      tableData: [],
      tableDataDay: [],
      rms_i_arr: {},
      rms_u_arr: {},
      options: [{
        value: '2024',
        label: '2024'
      }, {
        value: '2023',
        label: '2023'
      }],
      value: '',
      month: ''
    }
  },
  mounted () {
    this.getCurrentDate()
    this.getValue()
    this.getDayValue()
  },
  //  A 页面（组件）
  computed: {
    status () {
      // return this.$store.alert.confirmIndex
      // return this.$store.state.chooseDate
      return this.$store.state.chooseDevice //  Vuex 中定义的属性
      // eslint-disable-next-line no-unreachable
    }
  },
  watch: {
    status () {
      this.date = this.$store.state.chooseDate
      // console.log('this.date',this.date)
      this.getValue()
    }
  },
  methods: {
    /* 下拉框选中年份 */
    getYear () {
      // console.log('year', this.value)
      this.date = this.value
      this.getValue()
    },
    /* 点击左边月份右边更新 */
    clickDate (row) {
      // console.log('a', row)
      this.month = row.time
      this.getDayValue()
    },
    getValue () {
      // console.log('window.localStorage.token', window.localStorage.deviceId)
      this.$apiFun.postDevInPacMonthStatistics({
        userId: window.localStorage.userId,
        token: window.localStorage.token,
        code: window.localStorage.code,
        dev_id: window.localStorage.deviceId,
        date: this.date
      }).then(
        res => {
          console.log('value', res)
          let list = []
          console.log('list', res.statisticsList)
          list = res.statisticsList.pac_list
          // console.log('list', res.statisticsList)
          this.tableData = list
          // this.rms_i_arr = res.statisticsTable.rms_i_arr
          this.rms_u_arr = res.statisticsTable
          // console.log('this.rms_i_arr', this.rms_i_arr)
          // this.getEchartI()
          this.getEchartsU()
        }
      )
    },
    getDayValue () {
      // console.log('window.localStorage.token', window.localStorage.deviceId)
      this.$apiFun.postDevInPacDayStatistics({
        userId: window.localStorage.userId,
        token: window.localStorage.token,
        code: window.localStorage.code,
        dev_id: window.localStorage.deviceId,
        date: this.month
      }).then(
        res => {
          // console.log('valueD', res)
          let list = []
          list = res.statisticsList.pac_list
          // console.log('list', list)
          this.tableDataDay = list
          // this.rms_i_arr = res.statisticsTable.rms_i_arr
          this.rms_u_arr = res.statisticsTable
          // console.log('this.rms_i_arr', this.rms_i_arr)
          // this.getEchartI()
          this.getEchartsU()
        }
      )
    },
    getCurrentDate (n) {
      const dd = new Date()
      if (n) {
        dd.setDate(dd.getDate() - n)
      }
      const year = dd.getFullYear()
      const month =
        dd.getMonth() + 1 < 10 ? '0' + (dd.getMonth() + 1) : dd.getMonth() + 1
      const day = dd.getDate() < 10 ? '0' + dd.getDate() : dd.getDate()
      // console.log(' year + \'-\' + month + \'-\' + day', year + '-' + month + '-' + day)
      this.date = year + '-' + month + '-' + day
      this.month = year + '-' + month
      // console.log('time', this.time)
      /* 下拉框中的年份 */
      let newYear = year
      const arr = []
      while (newYear >= 2023) {
        arr.push({
          value: newYear,
          label: newYear
        })
        newYear--
      }
      // console.log('arr', arr)
      this.options = arr
    },
    getEchartsU () {
      let dataList = []
      let labels = []
      dataList = this.rms_u_arr.dataList
      labels = this.rms_u_arr.labels
      // console.log('labels!!!', this.rms_u_arr.dataList)
      const chartDom = document.getElementById('echartU')
      const myChart = echarts.init(chartDom)
      let option
      option = {
        title: {
          text: '电量（kWh）', // 主标题名称
          x: '40',
          textStyle: { // 主标题文本设置
            color: '#fff', // 颜色
            fontSize: 19, // 大小
            fontStyle: 'oblique', // 斜体
            fontWeight: '500', // 粗体
            fontFamily: 'SimHei, serif', // 字体
            textBorderColor: '#000' // 描边
            // textBorderWidth: '1', // 描边的宽度
            // textShadowColor: '#8c8c8c', // 阴影颜色
            // textShadowBlur: '5', // 阴影的宽度
            // textShadowOffsetX: '-10', // 阴影向X偏移
            // textShadowOffsetY: '30' // 阴影向Y偏移
          }
        },
        xAxis: {
          type: 'category',
          data: labels,
          axisLine: {
            // x轴线的颜色以及宽度
            show: true,
            lineStyle:
              {
                color: '#fff',
                width: 0,
                type: 'solid'
              }
          }
        },
        yAxis: {
          type: 'value',
          axisLine: {
            // x轴线的颜色以及宽度
            show: true,
            lineStyle:
              {
                color: '#fff',
                width: 0,
                type: 'solid'
              }
          }
        },
        // color: ['#FFFF00', '#00FF00', '#FF0000'],
        color: ['#1878ef'],

        legend: {
          icon: 'circle',
          itemHeight: 10, // 修改icon图形大小
          textStyle: {
            fontSize: 14,
            color: '#fff'
          },
          // x: "left", //可设定图例在左、右、居中
          // y: "top", //可设定图例在上、下、居中
          padding: [30, 0, 0, 32] // 可设定图例[距上方距离，距右方距离，距下方距离，距左方距离]
        },
        series: [
          {
            name: '电量（kWh）',
            data: dataList,
            type: 'bar',
            smooth: true,
            lineStyle: {
              width: 3
            }
          }
        ],
        tooltip: { // 鼠标悬浮提示框显示 X和Y 轴数据
          trigger: 'axis',
          backgroundColor: 'rgba(32, 33, 36,.7)',
          borderColor: 'rgba(32, 33, 36,0.20)',
          borderWidth: 1,
          textStyle: { // 文字提示样式
            color: '#fff',
            fontSize: '12'
          },
          axisPointer: { // 坐标轴虚线
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        }
      }

      option && myChart.setOption(option)
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep .has-gutter {
  line-height: 10px;
}

::v-deep .el-table {
  background-color: #033c76 !important; /* 背景透明 */
}

::v-deep .el-table th {
  color: #ffffff; /* 字体颜色 */
  font-size: 20px;
  font-weight: 400;
  background-color: #033c76 !important; /* 背景透明 */
  border: 0;
}

::v-deep .el-table tr, .el-table td {
  color: #e5dada;
  font-size: 20px;
  background-color: #033c76 !important; /* 背景透明 */
  border: 0;
}

::v-deep .el-table--enable-row-hover .el-table__body tr:hover > td {
  background-color: #fff !important;
  color: #000;
}
</style>
