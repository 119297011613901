<template>
  <div class="main">
    <el-table :data="tableData" max-height="787">
      <el-table-column
        prop="go_name"
        align="center"
        label="套餐名称"
        width="320px">
      </el-table-column>
      <el-table-column
        prop="go_desc"
        align="center"
        label="套餐描述"
        width="300px">
      </el-table-column>
      <el-table-column
        prop="go_total_amount"
        align="center"
        label="套餐金额"
        width="200px">
      </el-table-column>
      <el-table-column
        prop="gb_en_id"
        align="center"
        label="企业标识"
        width="200px">
      </el-table-column>
      <el-table-column
        prop="gb_code"
        align="center"
        label="企业编码"
        width="200px">
      </el-table-column>
      <el-table-column
        prop="gb_en_name"
        align="center"
        label="企业名称"
        width="280px">
      </el-table-column>
      <el-table-column
        prop="gb_user_name"
        align="center"
        label="联系人"
        width="200px">
      </el-table-column>
      <el-table-column
        prop="gb_user_phone"
        align="center"
        label="联系方式"
        width="200px">
      </el-table-column>
      <el-table-column
        prop="gb_gmt_create"
        align="center"
        label="交易创建时间"
        width="200px">
      </el-table-column>
      <el-table-column
        prop="gb_gmt_payment"
        align="center"
        label="交易付款时间"
        width="200px">
      </el-table-column>
      <el-table-column
        prop="gb_type"
        align="center"
        label="支付方式"
        width="200px"
        :formatter="gb_typeFormat">
      </el-table-column>
      <el-table-column
        prop="gb_out_trade_no"
        align="center"
        label="商品订单编号"
        width="280px">
      </el-table-column>
      <el-table-column
        prop="gb_trade_no"
        align="center"
        label="交易号"
        width="300px">
      </el-table-column>
      <el-table-column
        prop="gb_seller_id"
        align="center"
        label="卖家支付宝账号 ID"
        width="220px">
      </el-table-column>
      <el-table-column
        prop="gb_buyer_id"
        align="center"
        label="买家支付宝账号 ID"
        width="220px">
      </el-table-column>
      <el-table-column
        prop="gb_receipt_amount"
        align="center"
        label="实收金额"
        width="200px">
      </el-table-column>
      <el-table-column
        prop="status"
        align="center"
        label="发票状态"
        width="200px"
        :formatter="statusFormat">
      </el-table-column>
      <el-table-column
        prop="gd_invoce"
        align="center"
        label="下载发票"
        width="200px">
        <template v-slot="scope">
          <a :href="scope.row.gd_invoce"  class="buttonText" >下载发票</a>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { postGetServiceInfo } from '@/api/api'

export default {
  name: 'serviceInfo',
  data () {
    // eslint-disable-next-line no-unused-expressions
    return {
      tableData: [],
      statusHerf: ''
    }
  },
  mounted () {
    this.getValue()
  },
  methods: {
    getValue () {
      this.$apiFun.postGetServiceInfo({
        userId: window.localStorage.userId,
        token: window.localStorage.token,
        code: window.localStorage.code
      }).then(
        res => {
          console.log('service', res)
          // console.log('list', list)
          this.tableData = res.rows
        }
      )
    },
    gb_typeFormat (row) {
      return row.gb_type === 0 ? '支付宝' : '微信'
    },
    statusFormat (row) {
      return row.status === 0 ? '未开发票' : '已开发票'
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep .el-table {
  background-color: #033c76 !important; /* 背景透明 */
}

::v-deep .el-table th {
  color: #ffffff; /* 字体颜色 */
  font-size: 20px;
  font-weight: 400;
  background-color: #033c76 !important; /* 背景透明 */
  border: none;
}

::v-deep .el-table--border .el-table__cell, .el-table__body-wrapper .el-table--border.is-scrolling-left ~ .el-table__fixed {
  border-right: none;
}

::v-deep .el-table tr, .el-table td {
  color: #e5dada;
  font-size: 18px;
  background-color: #033c76 !important; /* 背景透明 */
}

::v-deep .el-table--enable-row-hover .el-table__body tr:hover > td {
  background-color: #fff !important;
  color: #000;
}

a {
  color: white; /* 未访问的链接 */
}

a:visited {
  color: white; /* 用户已访问的链接 */
}

a:hover {
  color: red; /* 鼠标悬停在链接上 */
}


</style>
