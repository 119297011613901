<template>
  <div class="main" style="height: 820px">
    <!--    电容-->
    <div v-if='this.$store.state.dev_category==4? false:true'>
      <div class="top" style="margin-top: 10px;">
        <div class="left boderStyle" style="width: 450px;height: 395px;float: left">
          <el-table
            :data="tableData4"
            :header-cell-style="{'text-align':'center'}"
            :cell-style="{'text-align':'center'}"
            style="width: 100%;"
            max-height="390px">
            <el-table-column
              prop="name"
              width="150">
            </el-table-column>
            <el-table-column
              prop="a"
              label="A相"
              width="100">
            </el-table-column>
            <el-table-column
              prop="b"
              label="B相"
              width="100">
            </el-table-column>
            <el-table-column
              prop="c"
              label="C相"
              width="100">
            </el-table-column>
          </el-table>

        </div>
        <div class="center  top" style="width: 640px;height: 390px; float: left">
          <div class="boderStyle" id="eletric"
               style="width: 400px;height: 390px; float: left;margin-left: 5px ;padding-left: 5px;padding-top: 5px">
            <el-table
              :data="tableData3"
              :header-cell-style="{'text-align':'center'}"
              :cell-style="{'text-align':'center'}"
              style="width: 100%;"
              max-height="390px">
              <el-table-column
                prop="name"
                width="190">
              </el-table-column>
              <el-table-column
                prop="num"
                width="160">
              </el-table-column>
            </el-table>
            <!--          <div class="sycm" style="display: flex;justify-content: space-around;flex-direction: column">-->
            <!--            <ul class="clearfix">-->
            <!--              <li>-->
            <!--                <h2>1824</h2>-->
            <!--                <span>总电量</span></li>-->
            <!--              <li>-->
            <!--                <h2>1920</h2>-->
            <!--                <span>当年总电量</span></li>-->
            <!--              <li>-->
            <!--                <h2>19%</h2>-->
            <!--                <span>当月总电量</span></li>-->

            <!--              <li>-->
            <!--                <h2>1824</h2>-->
            <!--                <span>当日总电量</span></li>-->

            <!--            </ul>-->
            <!--          </div>-->
          </div>
          <div class="boderStyle" id="cap"
               style="width: 400px;height: 390px; float: left;margin-left: 5px ;padding-left: 5px;padding-top: 5px">
            <el-table
              :data="tableData3"
              :header-cell-style="{'text-align':'center'}"
              :cell-style="{'text-align':'center'}"
              style="width: 100%;"
              max-height="390px">
              <el-table-column
                prop="name"
                width="160">
              </el-table-column>
              <el-table-column
                prop="num"
                width="150">
              </el-table-column>
            </el-table>
          </div>
          <div class="boderStyle" style="width: 290px;height: 390px; float: left; padding-left: 5px; padding-top: 5px">
            <el-table
              :data="tableData2"
              :header-cell-style="{'text-align':'center'}"
              :cell-style="{'text-align':'center'}"
              style="width: 100%;"
              max-height="390px">
              <el-table-column
                prop="name"
                width="170">
              </el-table-column>
              <el-table-column
                prop="number"
                width="100">
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div class="right boderStyle" style="width: 500px;height: 395px; float: left; margin-left: 5px ;padding-left: 5px;">
          <el-table
            :data="tableData1"
            :header-cell-style="{'text-align':'center'}"
            :cell-style="{'text-align':'center'}"
            style="width: 100%;"
            max-height="390px">
            <el-table-column
              prop="name"
              width="200">
            </el-table-column>
            <el-table-column
              prop="a"
              label="A相"
              width="100">
            </el-table-column>
            <el-table-column
              prop="b"
              label="B相"
              width="100">
            </el-table-column>
            <el-table-column
              prop="c"
              label="C相"
              width="100">
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="bottom top boderStyle" style="margin-top: 10px;">
        <div id="eletric1">
          <div v-if="this.$store.state.dev_p_type ==  0? true:false">
            <div id="echartU" style="height: 420px;width: 800px;float: left"></div>
            <div id="echartI" style="height: 420px;width: 800px;float: right"></div>
          </div>
          <div v-if="this.$store.state.dev_p_type ==  1? true:false">
            <div id="" style="width: 1600px;height: 430px;" v-if="this.$store.state.dev_type == 1">
              <div class="boderStyle"
                   style="width: 1620px;height: 30px;background-color: transparent;color: #FFFFFF;line-height: 0;padding-top: 30px;margin-left: -10px">
                并网列表
              </div>
              <el-table
                :data="pwSubList"
                :header-cell-style="{'text-align':'center'}"
                :cell-style="{'text-align':'center'}"
                max-height="430"
                @row-click="clickToJumpEle">
                <el-table-column
                  prop="index"
                  label="并网柜编号"
                  width="220">
                </el-table-column>
                <el-table-column
                  prop="name"
                  label="并网柜名称"
                  width="380">
                </el-table-column>
                <el-table-column
                  prop="capacity"
                  label="容量 （kw）"
                  width="540">
                </el-table-column>
                <el-table-column
                  prop="num"
                  label="设备数量（台）"
                  width="225">
                </el-table-column>
                <el-table-column
                  prop="eday"
                  label="今日发电量  (度)"
                  width="225">
                </el-table-column>
              </el-table>
            </div>
            <div id="" style="width: 1600px;height: 430px;"
                 v-if="this.$store.state.dev_type == 2 || this.$store.state.dev_type == 0">
              <div v-show="this.$store.state.dev_type == 2&& this.$store.state.mt_type == 0 ? true: false"
                   style="width: 1600px;height: 430px;">
                <div>
                  <div id="echartUBing" style="height: 420px;width: 800px;float: left"></div>
                  <div id="echartIBing" style="height: 420px;width: 800px;float: right"></div>
                </div>
              </div>
              <div v-if="this.$store.state.mt_type == 1 || this.$store.state.mt_type == 0? true: false"
                   style="width: 1600px;height: 430px;">
                <div v-if="this.$store.state.dev_type == 2 && this.$store.state.mt_type == 1  ? true:false">
                  <div class="boderStyle"
                       style="width: 1620px;height: 30px;background-color: transparent;color: #FFFFFF;line-height: 0;padding-top: 30px;margin-left: -10px">
                    设备列表
                  </div>
                  <el-table
                    :data="metSubDevList"
                    :header-cell-style="{'text-align':'center'}"
                    :cell-style="{'text-align':'center'}"
                    max-height="430"
                    @row-click="clickToJumpDev">
                    <el-table-column
                      prop="dev_id"
                      label="设备标识ID"
                      width="300">
                    </el-table-column>
                    <el-table-column
                      prop="index"
                      label="序号"
                      width="150">
                    </el-table-column>
                    <el-table-column
                      prop="dev_name"
                      label="设备名称"
                      width="500">
                    </el-table-column>
                    <el-table-column
                      prop="dev_ctw_sn"
                      label="设备编号"
                      width="225">
                    </el-table-column>
                    <el-table-column
                      prop="todaysWp"
                      label="当日总电量"
                      width="225">
                    </el-table-column>
                    <el-table-column
                      prop="pf_t"
                      label="总功率因数"
                      width="225">
                    </el-table-column>
                  </el-table>
                </div>
                <div v-show="this.$store.state.dev_type == 0&& this.$store.state.mt_type == 0 ? true:false">
                  <div id="echartUDev" style="height: 420px;width: 800px;float: left"></div>
                  <div id="echartIDev" style="height: 420px;width: 800px;float: right"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="cap1" style="width: 1600px;height: 420px;">
          <div class="boderStyle"
               style="width: 1620px;height: 30px;background-color: transparent;color: #FFFFFF;line-height: 0;padding-top: 30px;margin-left: -10px">
            设备状态
          </div>
          <el-table
            :data="tableData5"
            :cell-style="cellStyle"
            max-height="338px">
            <el-table-column
              prop="name"
              width="260">
            </el-table-column>
            <el-table-column
              prop="sub_dev_status"
              label="状态"
              width="260">
            </el-table-column>
            <el-table-column
              prop="compensation_method"
              label="补偿方式"
              width="260">
            </el-table-column>
            <el-table-column
              prop="volume"
              label="容量"
              width="260">
            </el-table-column>
            <el-table-column
              prop="circuit_changer_info"
              label="投切"
              width="260">
            </el-table-column>
            <el-table-column
              prop="temperature_status"
              label="温度"
              width="300">
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <!--     逆变器-->
    <!--    this.$store.state.dev_category==4-->
    <div v-if='this.$store.state.dev_category==4? true:false'>
      <div v-if="this.$store.state.dev_type==1? true:false">
        <div class="top" style="margin-top: 10px;">
          <div class="right boderStyle" style="width: 800px;height: 380px; float: left">
            <el-table
              :data="invertersList1"
              :header-cell-style="{'text-align':'center'}"
              :cell-style="{'text-align':'center'}"
              style="width: 100%;"
              max-height="425px">
              <el-table-column
                prop="name"
                width="400">
              </el-table-column>
              <el-table-column
                prop="value"
                width="400">
              </el-table-column>
            </el-table>
          </div>
          <div class="right boderStyle" style="width: 800px;height: 380px; float: right">
            <el-table
              :data="invertersList2"
              :header-cell-style="{'text-align':'center'}"
              :cell-style="{'text-align':'center'}"
              style="width: 100%;"
              max-height="425px">
              <el-table-column
                prop="name"
                width="400">
              </el-table-column>
              <el-table-column
                prop="value"
                width="400">
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div class="bottom top boderStyle" style="margin-top: 10px;">
          <div id="" style="width: 1600px;height: 435px;">
            <div class="boderStyle"
                 style="width: 1620px;height: 30px;background-color: transparent;color: #FFFFFF;line-height: 0;padding-top: 30px;margin-left: -10px">
              并网列表
            </div>
            <el-table
              :data="inPWSubList"
              :header-cell-style="{'text-align':'center'}"
              :cell-style="{'text-align':'center'}"
              max-height="435px"
              @row-click="clickToJump">
              <el-table-column
                prop="index"
                label="并网柜编号"
                width="220">
              </el-table-column>
              <el-table-column
                prop="name"
                label="并网柜名称"
                width="380">
              </el-table-column>
              <el-table-column
                prop="capacity"
                label="容量 （kw）"
                width="540">
              </el-table-column>
              <el-table-column
                prop="num"
                label="设备数量（台）"
                width="225">
              </el-table-column>
              <el-table-column
                prop="eday"
                label="今日发电量  (度)"
                width="225">
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
      <div v-if="this.$store.state.dev_type==2? true:false">
        <div class="top" style="margin-top: 10px;">
          <div class="right boderStyle" style="width: 800px;height: 380px; float: left">
            <el-table
              :data="invertersList1"
              :header-cell-style="{'text-align':'center'}"
              :cell-style="{'text-align':'center'}"
              style="width: 100%;"
              max-height="425px">
              <el-table-column
                prop="name"
                width="400">
              </el-table-column>
              <el-table-column
                prop="value"
                width="400">
              </el-table-column>
            </el-table>
          </div>
          <div class="right boderStyle" style="width: 800px;height: 380px; float: right">
            <el-table
              :data="invertersList2"
              :header-cell-style="{'text-align':'center'}"
              :cell-style="{'text-align':'center'}"
              style="width: 100%;"
              max-height="425px">
              <el-table-column
                prop="name"
                width="400">
              </el-table-column>
              <el-table-column
                prop="value"
                width="400">
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div class="bottom top boderStyle" style="margin-top: 10px;">
          <div id="" style="width: 1600px;height: 435px;">
            <div class="boderStyle"
                 style="width: 1620px;height: 30px;background-color: transparent;color: #FFFFFF;line-height: 0;padding-top: 30px;margin-left: -10px">
              逆变器列表
            </div>
            <el-table
              :data="inSubList"
              :header-cell-style="{'text-align':'center'}"
              :cell-style="{'text-align':'center'}"
              max-height="435px"
              @row-click="clickToJump">
              <el-table-column
                prop="index"
                label="序号"
                width="220">
              </el-table-column>
              <el-table-column
                prop="sn"
                label="设备序列号"
                width="380">
              </el-table-column>
              <el-table-column
                prop="name"
                label="设备名称"
                width="540">
              </el-table-column>
              <el-table-column
                prop="capacity"
                label="容量 kw"
                width="225">
              </el-table-column>
              <el-table-column
                prop="status"
                label="状态"
                width="225">
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
      <div v-if="this.$store.state.dev_type==0? true:false">
        <div class="top" style="margin-top: 10px;">
          <div class="right boderStyle" style="width: 260px;height: 380px; float: left;line-height: 20px">
            <p style="height: 130px;margin-top: 40px">
              <img style="width: 110px;height: 100px"
                   src="../../../assets/images/weather/qing.png"
                   alt="" v-if="this.weather  == '晴'"
              />
              <img style="width: 110px;height: 100px"
                   src="../../../assets/images/weather/yin.png"
                   alt="" v-if="this.weather  == '阴'"
              />
              <img style="width: 110px;height: 100px"
                   src="../../../assets/images/weather/duoyunzhuanyin.png"
                   alt="" v-if="this.weather.indexOf('多云') !=-1"
              />
              <img style="width: 110px;height: 100px"
                   src="../../../assets/images/weather/zhenyu.png"
                   alt="" v-if="this.weather  == '阵雨'"
              />
              <img style="width: 110px;height: 100px"
                   src="../../../assets/images/weather/leizhenyu.png"
                   alt="" v-if="this.weather  == '雷阵雨'"
              />
              <img style="width: 110px;height: 100px"
                   src="../../../assets/images/weather/xiaoyu.png"
                   alt=""
                   v-if="this.weather  == '小雨'|| this.weather == '小雨转阴' || this.weather == '阴转小雨' ||this.weather.indexOf('雨') != -1"
              />
              <img style="width: 110px;height: 100px"
                   src="../../../assets/images/weather/zhongyu.png"
                   alt="" v-if="this.weather  == '中雨'"
              />
              <img style="width: 110px;height: 100px"
                   src="../../../assets/images/weather/dayu.png"
                   alt="" v-if="this.weather  == '大雨'"
              />
              <img style="width: 110px;height: 100px"
                   src="../../../assets/images/weather/baoyu.png"
                   alt="" v-if="this.weather  == '暴雨'"
              />
              <img style="width: 110px;height: 100px"
                   src="../../../assets/images/weather/xiaoxue.png"
                   alt="" v-if="this.weather  == '小雪'|| this.weather.indexOf('雪') != -1"
              />
              <img style="width: 110px;height: 100px"
                   src="../../../assets/images/weather/zhongxue.png"
                   alt="" v-if="this.weather  == '中雪'"
              />
              <img style="width: 110px;height: 100px"
                   src="../../../assets/images/weather/daxue.png"
                   alt="" v-if="this.weather  == '大雪' || this.weather  == '暴雪'"
              />
              <img style="width: 110px;height: 100px"
                   src="../../../assets/images/weather/yujiaxue.png"
                   alt="" v-if="this.weather  == '雨夹雪'"
              />
              <img style="width: 110px;height: 100px"
                   src="../../../assets/images/weather/zhenxue.png"
                   alt="" v-if="this.weather  == '阵雪'"
              />
            </p>
            <p>{{ this.invertersList1[2].name }} {{ this.invertersList1[2].value }}</p>
            <p>{{ this.invertersList1[3].name }} {{ this.invertersList1[3].value }}</p>
            <p>{{ this.invertersList1[4].name }} {{ this.invertersList1[4].value }}</p>
            <p>{{ this.invertersList1[5].name }} {{ this.invertersList1[5].value }}</p>
          </div>
          <div class="right " style="width: 1340px;height: 380px; float: right">
            <div class="boderStyle" style="width: 702px;height: 380px; float: left;">
              <div style="width: 300px;height: 380px; float: left;line-height: 20px">
                <p style="height: 130px; margin-top: 50px">
                  <img style="width: 100px;height: 100px" src="../../../assets/images/dianliang.png"
                  >
                </p>
                <p style="text-align: left;padding-left: 80px;margin-top: 10px">累计发电小时（h）</p>
                <p style="text-align: left;padding-left: 80px;margin-top: 40px">累计发电量（kW·h）</p>
                <p style="text-align: left;padding-left: 80px;margin-top: 40px">发电功率（kW）</p>

              </div>
              <div style="width: 340px;height: 380px; float: right; line-height: 20px">
                <div style="height: 140px">
                  <p style="font-size: 30px;font-weight: 600">今日发电量（kW·h）</p>
                  <p style="margin-top: 50px;color: #e9a825;font-size: 32px;font-weight: 600">
                    {{ this.inventersDetialInfo.eDay }}</p>
                </div>
                <p style="color: #e9a825; font-size: 28px;font-weight: 600;">{{ this.inventersDetialInfo.hTotal }}</p>
                <p style="color: #e9a825;font-size: 28px;margin-top: 40px; font-weight: 600">
                  {{ this.inventersDetialInfo.eTotal }}</p>
                <p style="color: #e9a825;font-size: 28px;margin-top: 40px; font-weight: 600">
                  {{ this.inventersDetialInfo.out_pac }}</p>
              </div>

            </div>
            <div class="boderStyle" style="width: 622px;height: 380px; float: right;">
              <div style="width: 250px;line-height: 20px;float: left;margin-top: 40px">
                <p><img style="width: 80px;height: 80px" src="../../../assets/images/guangfu.png">设备名称:</p>
                <p><img style="width: 110px;height: 60px" src="../../../assets/images/xuliehao.png">序列号:</p>
                <p><img style="width: 60px;height: 60px" src="../../../assets/images/zhuangtai.png"> 设备状态:</p>

              </div>
              <div style="width: 370px;float: right;margin-top: 40px;text-align: left">
                <p style="line-height: 80px">{{ this.invertersList2[1].value }} </p>
                <p style="line-height: 60px">{{ this.invertersList2[0].value }}</p>
                <p style="line-height: 50px">{{ this.invertersList2[3].value }}</p>

              </div>

            </div>
          </div>
        </div>
        <div class="bottom top boderStyle" style="margin-top: 10px;">
          <div id="" style="width: 1600px;height: 435px;">
            <div class="boderStyle"
                 style="width: 1620px;height: 30px;background-color: transparent;color: #FFFFFF;line-height: 0;padding-top: 30px;margin-left: -10px">
              逆变器详情
            </div>
            <el-table
              :data="alarmMessage" style="height:810px"
              max-height="365">
              <el-table-column
                prop="name"
                width="800">
              </el-table-column>
              <el-table-column
                prop="value"
                width="800">
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import * as echarts from 'echarts'
import { postGetInverterATInfoBySn, postGetInverterSubInfoBySn, postGetInverterSubList } from '@/api/api'

export default {
  name: 'liveData',
  data () {
    return {
      date: this.$store.state.chooseDate,
      weather: '',
      tableData: [],
      tableData1: [],
      tableData2: [],
      tableData3: [],
      tableData4: [],
      tableData5: [],
      inPWSubList: [], // 电站下并网列表
      inSubList: [],
      invertersList1: [],
      invertersList2: [],
      inverterSubList: [],
      alarmMessage: [],
      pwSubList: [],
      inventersDetialInfo: '',
      metSubDevList: [],
      show: false
    }
  },
  //  A 页面（组件）
  computed: {
    status () {
      // return this.$store.alert.confirmIndex
      return this.$store.state.chooseDevice //  Vuex 中定义的属性
    }
  },
  watch: {
    status () {
      this.date = this.$store.state.chooseDate
      if (this.$store.state.dev_category == 4) {
        this.getInvertersValue()
      } else {
        // console.log('调用')
        this.getValue()
      }
      this.view()
    }
  },
  mounted () {
    this.getCurrentDate()
    // console.log('this.$store.state.dev_category == 4',this.$store.state.dev_category)
    if (this.$store.state.dev_category == 4) {
      this.getInvertersValue()
    } else {
      this.getValue()
    }
    this.view()
  },
  methods: {
    cellStyle ({
      row,
      column,
      rowIndex,
      columnIndex
    }) {
      // console.log('column',row)
      if (column.property === 'sub_dev_status') {
        switch (row.sub_dev_status) {
          case '故障':
            return {
              color: 'red'
            }
            break
          case '正常':
            return {
              color: 'green'
            }
            break
        }
      }
      if (column.property === 'temperature_status') {
        switch (row.temperature_status) {
          case '故障':
            return {
              color: 'red'
            }
            break
          case '正常':
            return {
              color: 'green'
            }
            break
        }
      }
    },
    getValue () {
      // console.log('getvalue window.localStorage.deviceId', window.localStorage.deviceId)
      this.$apiFun.postCurrentValue({
        userId: window.localStorage.userId,
        token: window.localStorage.token,
        code: window.localStorage.code,
        dev_id: window.localStorage.deviceId,
        date: this.date
      }).then(
        res => {
          // console.log('value', res)
          this.$store.commit('setMt_type', res.actualTimeData.mt_type)
          // console.log('this.$store.state.mt_type', res.actualTimeData.mt_type)
          let list = []
          let list1 = []
          let list2 = []
          let list3 = []
          let list4 = []
          let list5 = []
          list = res.actualTimeData
          list1 = res.actualTimeData.pqList // 功率因素
          list2 = res.actualTimeData.pfList // 电压不平衡
          list4 = res.actualTimeData.rtList // 电压电流有效值
          // console.log('list', list)
          this.tableData = list
          this.tableData1 = list1
          this.tableData2 = list2
          this.tableData4 = list4
          // console.log('window.localStorage.dev_category', this.$store.alert.dev_category )
          if (this.$store.state.dev_category === 1) {
            list3 = res.actualTimeData.capList // 电容状态
            list5 = res.actualTimeData.capSubDevList
            this.tableData3 = list3
            this.tableData5 = list5
            setTimeout(this.cellStyle, 40000)
            // console.log('cap', this.tableData3)
          } else {
            list3 = res.actualTimeData.meterList // 多功能表状态
            this.tableData3 = list3
            this.pwSubList = res.actualTimeData.pwSubList
            this.metSubDevList = res.actualTimeData.metSubDevList
            this.$store.commit('setMt_type', res.actualTimeData.mt_type)
            console.log('多功能表res.actualTimeData.metSubDevList', res.actualTimeData.metSubDevList)
            if (this.$store.state.dev_p_type == 0) {
              // console.log(1)
              this.getEchartU()
              this.getEchartI()
            } else if (this.$store.state.dev_p_type == 1 && this.$store.state.dev_type == 2) {
              // console.log(2,this.$store.state.dev_p_type == 1 && this.$store.state.mt_type == 0,this.$store.state.mt_type)
              this.getEchartUBing()
              this.getEchartIBing()
            } else if (this.$store.state.dev_p_type == 1 && this.$store.state.dev_type == 0) {

              // console.log(3)
              // console.log(3,this.$store.state.dev_p_type == 1 && this.$store.state.mt_type == 0&& this.$store.state.dev_type ==0)
              // console.log('getvalue 中的mt_type', this.$store.state.mt_type)
              this.getEchartUDev()
              this.getEchartIDev()
            }
            // console.log('this.inPWSubList', this.inPWSubList)
          }
          // this.rms_i_arr = res.statisticsTable.rms_i_arr
          // this.rms_u_arr = res.statisticsTable.rms_u_arr
          // console.log('tableData3', this.tableData3)
        }
      )
    },
    // 光伏
    getInvertersValue () {
      // console.log('光伏window.localStorage.userId', window.localStorage.deviceId)
      this.$apiFun.postGetInverterATInfoBySn({
        userId: window.localStorage.userId,
        token: window.localStorage.token,
        code: window.localStorage.code,
        dev_id: window.localStorage.deviceId
      }).then(
        res => {
          // console.log('value', res.devInventersAT.inPWSubList)
          this.inPWSubList = res.devInventersAT.inPWSubList
          this.inSubList = res.devInventersAT.inSubList
          this.invertersList1 = res.devInventersAT.pwInfo
          this.invertersList2 = res.devInventersAT.pwInfo1
          this.alarmMessage = res.devInventersAT.INList
          this.weather = res.devInventersAT.pwInfo[1].value
          this.inventersDetialInfo = res.devInventersAT.inventersDetialInfo
          // console.log('weather', res.devInventersAT.pwInfo[1].value)
          // console.log('this.weather.indexOf(\'多云\')',this.weather.search('多云'))
        }
      )
    },
    clickToJumpDev (row, event, column) {
      // console.log('row',row)
      this.$store.commit('setDev_type', row.dev_type)
      this.$store.commit('setDeviceId', row.dev_id)
      this.$store.commit('setMt_type', row.mt_type)
      const status = this.$store.state.chooseDevice// 重新赋值
      this.$store.commit('changeDevice', !status)
      // console.log('??this.$store.state.dev_type', this.$store.state.dev_type)
      // console.log('??this.$store.state.mt_type', this.$store.state.mt_type)
    },
    // 多功能表发电电站实时页面下并网列表的跳转
    clickToJumpEle (row, event, column) {
      // console.log('this.$store.state.dev_category', this.$store.state.dev_category)
      //
      // console.log('this.$store.state.m_type', this.$store.state.mt_type)
      // console.log('row', row)
      this.$store.commit('setDeviceId', row.dev_id)
      this.$store.commit('setDev_type', row.dev_type)
      this.$store.commit('setMt_type', row.mt_type)

      const status = this.$store.state.chooseDevice// 重新赋值
      this.$store.commit('changeDevice', !status)
    },
    // 电网实时页面跳转
    clickToJump (row, event, column) {
      // console.log('跳转', row)
      // console.log('row.dev_id',row.dev_id)
      this.$store.commit('setDeviceId', row.dev_id)
      this.$store.commit('setDev_type', row.dev_type)
      const status = this.$store.state.chooseDevice// 重新赋值
      this.$store.commit('changeDevice', !status)
    },
    // 告警信息详情
    getAlarmMessage (row, event, column) {
      // console.log(row)
      this.$apiFun.postGetInverterSubInfoBySn({
        userId: window.localStorage.userId,
        token: window.localStorage.token,
        code: window.localStorage.code,
        sn: row.sn
      }).then(
        res => {
          // console.log('tanchuang',res)
          this.alarmMessage = res.atList
          this.show = true // 控制弹窗显示
        }
      )
    },
    view () {
      if (this.$store.state.dev_category === 1) {
        // console.log(this.$store.alert.confirmIndex)
        document.getElementById('eletric').style.display = 'none'
        document.getElementById('eletric1').style.display = 'none'
        document.getElementById('cap').style.display = ''// 展示
        document.getElementById('cap1').style.display = ''// 展示
      } else {
        document.getElementById('eletric').style.display = ''
        document.getElementById('eletric1').style.display = ''
        document.getElementById('cap').style.display = 'none'
        document.getElementById('cap1').style.display = 'none'
      }
    },
    getCurrentDate (n) {
      const dd = new Date()
      if (n) {
        dd.setDate(dd.getDate() - n)
      }
      const year = dd.getFullYear()
      const month =
        dd.getMonth() + 1 < 10 ? '0' + (dd.getMonth() + 1) : dd.getMonth() + 1
      const day = dd.getDate() < 10 ? '0' + dd.getDate() : dd.getDate()
      // console.log(' year + \'-\' + month + \'-\' + day', year + '-' + month + '-' + day)
      this.date = year + '-' + month + '-' + day
      this.time = new Date().getTime()
      // console.log('time', this.time)
    },
    getEchartU () {
      let dataList = []
      let labels = []
      dataList = this.tableData.voltageOptions.dataList
      labels = this.tableData.voltageOptions.labels
      // console.log('labels!!!', dataList)
      const chartDom = document.getElementById('echartU')
      const myChart = echarts.init(chartDom)
      let option
      option = {
        color: ['#FFFF00', '#00FF00', '#FF0000'],
        xAxis: {
          type: 'category',
          data: labels,
          axisLine: {
            // x轴线的颜色以及宽度
            show: true,
            lineStyle:
              {
                color: '#fff',
                width: 0,
                type: 'solid'
              }
          }
        },
        yAxis: {
          type: 'value',
          axisLine: {
            // x轴线的颜色以及宽度
            show: true,
            lineStyle:
              {
                color: '#fff',
                width: 0,
                type: 'solid'
              }
          }
        },
        legend: {
          icon: 'circle',
          itemHeight: 10, // 修改icon图形大小
          textStyle: {
            fontSize: 14,
            color: '#fff'
          },
          // x: "left", //可设定图例在左、右、居中
          // y: "top", //可设定图例在上、下、居中
          padding: [30, 0, 0, 32] // 可设定图例[距上方距离，距右方距离，距下方距离，距左方距离]
        },
        series: [
          {
            name: 'A相电压',
            data: dataList[0],
            type: 'line',
            smooth: true,
            lineStyle: {
              width: 3
            }
          },
          {
            name: 'B相电压',
            data: dataList[1],
            type: 'line',
            smooth: true,
            lineStyle: {
              width: 3
            }
          },
          {
            name: 'C相电压',
            data: dataList[2],
            type: 'line',
            smooth: true,
            lineStyle: {
              width: 3
            }
          }
        ],
        tooltip: { // 鼠标悬浮提示框显示 X和Y 轴数据
          trigger: 'axis',
          backgroundColor: 'rgba(32, 33, 36,.7)',
          borderColor: 'rgba(32, 33, 36,0.20)',
          borderWidth: 1,
          textStyle: { // 文字提示样式
            color: '#fff',
            fontSize: '12'
          },
          axisPointer: { // 坐标轴虚线
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        }
      }

      option && myChart.setOption(option)
    },
    getEchartI () {
      let dataList = []
      let labels = []
      dataList = this.tableData.currentOptions.dataList
      labels = this.tableData.currentOptions.labels
      // console.log('labels!!!', this.rms_i_arr)
      const chartDom = document.getElementById('echartI')
      const myChart = echarts.init(chartDom)
      let option
      option = {
        color: ['#FFFF00', '#00FF00', '#FF0000'],
        tooltip: {},
        xAxis: {
          type: 'category',
          data: labels,
          axisLine: {
            // x轴线的颜色以及宽度
            show: true,
            lineStyle:
              {
                color: '#fff',
                width: 0,
                type: 'solid'
              }
          }
        },
        yAxis: {
          type: 'value',
          axisLine: {
            // x轴线的颜色以及宽度
            show: true,
            lineStyle:
              {
                color: '#fff',
                width: 0,
                type: 'solid'
              }
          }
        },
        legend: {
          icon: 'circle',
          itemHeight: 10, // 修改icon图形大小
          textStyle: {
            fontSize: 14,
            color: '#fff'
          },
          // x: "left", //可设定图例在左、右、居中
          // y: "top", //可设定图例在上、下、居中
          padding: [30, 0, 0, 32] // 可设定图例[距上方距离，距右方距离，距下方距离，距左方距离]
        },
        series: [
          {
            name: 'A相电流',
            data: dataList[0],
            type: 'line',
            smooth: true,
            lineStyle: {
              width: 3
            }
          },
          {
            name: 'B相电流',
            data: dataList[1],
            type: 'line',
            smooth: true,
            lineStyle: {
              width: 3
            }
          },
          {
            name: 'C相电流',
            data: dataList[2],
            type: 'line',
            smooth: true,
            lineStyle: {
              width: 3
            }
          }
        ],
        tooltip: { // 鼠标悬浮提示框显示 X和Y 轴数据
          trigger: 'axis',
          backgroundColor: 'rgba(32, 33, 36,.7)',
          borderColor: 'rgba(32, 33, 36,0.20)',
          borderWidth: 1,
          textStyle: { // 文字提示样式
            color: '#fff',
            fontSize: '12'
          },
          axisPointer: { // 坐标轴虚线
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        }
      }

      option && myChart.setOption(option)
    },
    getEchartUBing () {
      let dataList = []
      let labels = []
      dataList = this.tableData.voltageOptions.dataList
      labels = this.tableData.voltageOptions.labels
      // console.log('labels!!!', dataList)
      const chartDom = document.getElementById('echartUBing')
      const myChart = echarts.init(chartDom)
      let option
      // eslint-disable-next-line prefer-const
      option = {
        color: ['#FFFF00', '#00FF00', '#FF0000'],
        xAxis: {
          type: 'category',
          data: labels,
          axisLine: {
            // x轴线的颜色以及宽度
            show: true,
            lineStyle:
              {
                color: '#fff',
                width: 0,
                type: 'solid'
              }
          }
        },
        yAxis: {
          type: 'value',
          axisLine: {
            // x轴线的颜色以及宽度
            show: true,
            lineStyle:
              {
                color: '#fff',
                width: 0,
                type: 'solid'
              }
          }
        },
        legend: {
          icon: 'circle',
          itemHeight: 10, // 修改icon图形大小
          textStyle: {
            fontSize: 14,
            color: '#fff'
          },
          // x: "left", //可设定图例在左、右、居中
          // y: "top", //可设定图例在上、下、居中
          padding: [30, 0, 0, 32] // 可设定图例[距上方距离，距右方距离，距下方距离，距左方距离]
        },
        series: [
          {
            name: 'A相电压',
            data: dataList[0],
            type: 'line',
            smooth: true,
            lineStyle: {
              width: 3
            }
          },
          {
            name: 'B相电压',
            data: dataList[1],
            type: 'line',
            smooth: true,
            lineStyle: {
              width: 3
            }
          },
          {
            name: 'C相电压',
            data: dataList[2],
            type: 'line',
            smooth: true,
            lineStyle: {
              width: 3
            }
          }
        ],
        tooltip: { // 鼠标悬浮提示框显示 X和Y 轴数据
          trigger: 'axis',
          backgroundColor: 'rgba(32, 33, 36,.7)',
          borderColor: 'rgba(32, 33, 36,0.20)',
          borderWidth: 1,
          textStyle: { // 文字提示样式
            color: '#fff',
            fontSize: '12'
          },
          axisPointer: { // 坐标轴虚线
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        }
      }

      option && myChart.setOption(option)
    },
    getEchartIBing () {
      let dataList = []
      let labels = []
      dataList = this.tableData.currentOptions.dataList
      labels = this.tableData.currentOptions.labels
      // console.log('labels!!!', this.rms_i_arr)
      const chartDom = document.getElementById('echartIBing')
      const myChart = echarts.init(chartDom)
      let option
      option = {
        color: ['#FFFF00', '#00FF00', '#FF0000'],
        tooltip: {},
        xAxis: {
          type: 'category',
          data: labels,
          axisLine: {
            // x轴线的颜色以及宽度
            show: true,
            lineStyle:
              {
                color: '#fff',
                width: 0,
                type: 'solid'
              }
          }
        },
        yAxis: {
          type: 'value',
          axisLine: {
            // x轴线的颜色以及宽度
            show: true,
            lineStyle:
              {
                color: '#fff',
                width: 0,
                type: 'solid'
              }
          }
        },
        legend: {
          icon: 'circle',
          itemHeight: 10, // 修改icon图形大小
          textStyle: {
            fontSize: 14,
            color: '#fff'
          },
          // x: "left", //可设定图例在左、右、居中
          // y: "top", //可设定图例在上、下、居中
          padding: [30, 0, 0, 32] // 可设定图例[距上方距离，距右方距离，距下方距离，距左方距离]
        },
        series: [
          {
            name: 'A相电流',
            data: dataList[0],
            type: 'line',
            smooth: true,
            lineStyle: {
              width: 3
            }
          },
          {
            name: 'B相电流',
            data: dataList[1],
            type: 'line',
            smooth: true,
            lineStyle: {
              width: 3
            }
          },
          {
            name: 'C相电流',
            data: dataList[2],
            type: 'line',
            smooth: true,
            lineStyle: {
              width: 3
            }
          }
        ],
        tooltip: { // 鼠标悬浮提示框显示 X和Y 轴数据
          trigger: 'axis',
          backgroundColor: 'rgba(32, 33, 36,.7)',
          borderColor: 'rgba(32, 33, 36,0.20)',
          borderWidth: 1,
          textStyle: { // 文字提示样式
            color: '#fff',
            fontSize: '12'
          },
          axisPointer: { // 坐标轴虚线
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        }
      }

      option && myChart.setOption(option)
    },
    getEchartUDev () {
      // console.log('调用DEV')
      let dataList = []
      let labels = []
      console.log('this.tableData.voltageOptions.dataList', this.tableData)
      dataList = this.tableData.voltageOptions.dataList
      labels = this.tableData.voltageOptions.labels
      console.log('this.tableData.voltageOptions.dataList', this.tableData.voltageOptions.dataList)
      const chartDom = document.getElementById('echartUDev')
      const myChart = echarts.init(chartDom)
      let option
      option = {
        color: ['#FFFF00', '#00FF00', '#FF0000'],
        xAxis: {
          type: 'category',
          data: labels,
          axisLine: {
            // x轴线的颜色以及宽度
            show: true,
            lineStyle:
              {
                color: '#fff',
                width: 0,
                type: 'solid'
              }
          }
        },
        yAxis: {
          type: 'value',
          axisLine: {
            // x轴线的颜色以及宽度
            show: true,
            lineStyle:
              {
                color: '#fff',
                width: 0,
                type: 'solid'
              }
          }
        },
        legend: {
          icon: 'circle',
          itemHeight: 10, // 修改icon图形大小
          textStyle: {
            fontSize: 14,
            color: '#fff'
          },
          // x: "left", //可设定图例在左、右、居中
          // y: "top", //可设定图例在上、下、居中
          padding: [30, 0, 0, 32] // 可设定图例[距上方距离，距右方距离，距下方距离，距左方距离]
        },
        series: [
          {
            name: 'A相电压',
            data: dataList[0],
            type: 'line',
            smooth: true,
            lineStyle: {
              width: 3
            }
          },
          {
            name: 'B相电压',
            data: dataList[1],
            type: 'line',
            smooth: true,
            lineStyle: {
              width: 3
            }
          },
          {
            name: 'C相电压',
            data: dataList[2],
            type: 'line',
            smooth: true,
            lineStyle: {
              width: 3
            }
          }
        ],
        tooltip: { // 鼠标悬浮提示框显示 X和Y 轴数据
          trigger: 'axis',
          backgroundColor: 'rgba(32, 33, 36,.7)',
          borderColor: 'rgba(32, 33, 36,0.20)',
          borderWidth: 1,
          textStyle: { // 文字提示样式
            color: '#fff',
            fontSize: '12'
          },
          axisPointer: { // 坐标轴虚线
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        }
      }

      option && myChart.setOption(option)
    },
    getEchartIDev () {
      let dataList = []
      let labels = []
      dataList = this.tableData.currentOptions.dataList
      labels = this.tableData.currentOptions.labels
      // console.log('labels!!!', this.rms_i_arr)
      const chartDom = document.getElementById('echartIDev')
      const myChart = echarts.init(chartDom)
      let option
      option = {
        color: ['#FFFF00', '#00FF00', '#FF0000'],
        tooltip: {},
        xAxis: {
          type: 'category',
          data: labels,
          axisLine: {
            // x轴线的颜色以及宽度
            show: true,
            lineStyle:
              {
                color: '#fff',
                width: 0,
                type: 'solid'
              }
          }
        },
        yAxis: {
          type: 'value',
          axisLine: {
            // x轴线的颜色以及宽度
            show: true,
            lineStyle:
              {
                color: '#fff',
                width: 0,
                type: 'solid'
              }
          }
        },
        legend: {
          icon: 'circle',
          itemHeight: 10, // 修改icon图形大小
          textStyle: {
            fontSize: 14,
            color: '#fff'
          },
          // x: "left", //可设定图例在左、右、居中
          // y: "top", //可设定图例在上、下、居中
          padding: [30, 0, 0, 32] // 可设定图例[距上方距离，距右方距离，距下方距离，距左方距离]
        },
        series: [
          {
            name: 'A相电流',
            data: dataList[0],
            type: 'line',
            smooth: true,
            lineStyle: {
              width: 3
            }
          },
          {
            name: 'B相电流',
            data: dataList[1],
            type: 'line',
            smooth: true,
            lineStyle: {
              width: 3
            }
          },
          {
            name: 'C相电流',
            data: dataList[2],
            type: 'line',
            smooth: true,
            lineStyle: {
              width: 3
            }
          }
        ],
        tooltip: { // 鼠标悬浮提示框显示 X和Y 轴数据
          trigger: 'axis',
          backgroundColor: 'rgba(32, 33, 36,.7)',
          borderColor: 'rgba(32, 33, 36,0.20)',
          borderWidth: 1,
          textStyle: { // 文字提示样式
            color: '#fff',
            fontSize: '12'
          },
          axisPointer: { // 坐标轴虚线
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        }
      }

      option && myChart.setOption(option)
    }
  }
}
</script>

<style scoped lang="scss">
.top {
  display: flex;
  justify-content: space-around;
  margin-left: 0;
}

.boderStyle {
  border: 1px solid rgba(7, 118, 181, .7);
  box-shadow: inset 0 0 5px rgba(7, 118, 181, .8);
}

::v-deep .has-gutter {
  line-height: 15px;
}

::v-deep .el-table {
  background-color: #033c76 !important; /* 背景透明 */
}

::v-deep .el-table th {
  color: #ffffff; /* 字体颜色 */
  font-size: 18px;
  font-weight: 400;
  background-color: #033c76 !important; /* 背景透明 */
  border: 0;
}

::v-deep .el-table tr, .el-table td {
  color: #e5dada;
  font-size: 18px;
  background-color: #033c76 !important; /* 背景透明 */
  border: 0;
}

::v-deep .el-table--enable-row-hover .el-table__body tr:hover > td {
  background-color: #fff !important;
  color: #000;
}

::v-deep .el-descriptions__body {
  background-color: transparent !important;
  font-size: 20px;
  font-family: SimHei, serif;
}

::v-deep .el-descriptions__header {
  color: #FFFFFF;
  line-height: 30px;
}

::v-deep .el-descriptions__title {
  font-size: 22px;
  font-weight: 500;
  font-family: SimHei, serif;
}

::v-deep .el-descriptions-row {
  background: transparent !important;
}

// 弹出框样式
::v-deep .el-dialog__header {
  line-height: 0;
  color: #FFFFFF;
  background: rgb(2, 51, 103) !important;
  text-align: center;
  box-shadow: 0 0 1.5vw rgb(2, 51, 103) inset;
  background: linear-gradient(#5f9cf3, #5f9cf3) left top,
  linear-gradient(#5f9cf3, #5f9cf3) left top,
  linear-gradient(#5f9cf3, #5f9cf3) right top,
  linear-gradient(#5f9cf3, #5f9cf3) right top;
  /* linear-gradient(#3deeda, #3deeda) left bottom,
  linear-gradient(#3deeda, #3deeda) left bottom,
  linear-gradient(#3deeda, #3deeda) right bottom,
  linear-gradient(#3deeda, #3deeda) right bottom; */
  background-repeat: no-repeat;
  background-size: 2px 20px, 20px 2px;
}

::v-deep .el-dialog__title {
  color: #FFFFFF;
  font-size: 23px;
  padding: 0;
}

::v-deep .el-dialog__body {
  padding: 20px;
  color: #fff;
  background: rgb(2, 51, 103) !important;
  text-align: left;
  box-shadow: 0 0 1.5vw rgb(2, 51, 103) inset;
  background: linear-gradient(#5f9cf3, #5f9cf3) left bottom,
  linear-gradient(#5f9cf3, #5f9cf3) left bottom,
  linear-gradient(#5f9cf3, #5f9cf3) right bottom,
  linear-gradient(#5f9cf3, #5f9cf3) right bottom;
  background-repeat: no-repeat;
  background-size: 2px 20px, 20px 2px;
}

::v-deep .my-label {
  background: transparent !important;
  display: flex;
  color: #FFFFFF;
  line-height: 48px;
  height: 48px;
}

::v-deep .my-content {
  background: transparent !important;
  line-height: 48px;
  color: #FFFFFF;
  height: 48px;
}

li {
  margin-left: 20px;
  font-weight: 400;
}
</style>
